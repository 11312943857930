<template>
  <div>
    <move-in-detail :billNo="type" />
  </div>
</template>

<script>
import MoveInDetail from '@/views/components/shop/MoveInDetail.vue'
export default {
  name: 'ProduceDetail',
  components: { MoveInDetail },
  data () {
    return {
      type: '140307'
    }
  }
}
</script>

<style></style>
