<template>
  <ShopOrder />
</template>

<script>
import ShopOrder from './components'
export default {
  name: 'shopOrder',
  components: { ShopOrder }
}
</script>
